/** @jsx jsx */
import { jsx, useColorMode, Flex } from "theme-ui";
import useMinimalBlogConfig from "@lekoarts/gatsby-theme-minimal-blog/src/hooks/use-minimal-blog-config";
import ColorModeToggle from "@lekoarts/gatsby-theme-minimal-blog/src/components/colormode-toggle";
import Navigation from "./navigation";
import HeaderTitle from "./header-title";
import HeaderExternalLinks from "@lekoarts/gatsby-theme-minimal-blog/src/components/header-external-links";
import WeatherWidget from "./weather-widget";

const Header = () => {
  const { navigation: nav } = useMinimalBlogConfig();
  const [colorMode, setColorMode] = useColorMode();
  const isDark = colorMode === `dark`;
  const toggleColorMode = (e: any) => {
    e.preventDefault();
    setColorMode(isDark ? `light` : `dark`);
  };

  return (
    <header sx={{ mb: [4, 5] }}>
      <div
        sx={{
          boxSizing: `border-box`,
          display: `flex`,
          //variant: `dividers.bottom`,
          alignItems: `center`,
          justifyContent: `center`,
          mt: 3,
          color: `secondary`,
          a: { color: `secondary`, ":hover": { color: `heading` } },
          flexFlow: `wrap`
        }}
      >
        <Navigation nav={nav} />
        {false && <HeaderExternalLinks />}
      </div>
      <Flex sx={{ alignItems: `center`, justifyContent: `center` }}>
        <HeaderTitle />
        {false && <ColorModeToggle isDark={isDark} toggle={toggleColorMode} />}
        {false && <WeatherWidget />}
      </Flex>
    </header>
  );
};

export default Header;
