/** @jsx jsx */
import * as React from "react";
import { Global } from "@emotion/react";
import { Box, Container, jsx } from "theme-ui";
import "@fontsource/fjalla-one"; // Defaults to weight 400.
// import "@fontsource/hahmlet/200.css";
import Seo from "@lekoarts/gatsby-theme-minimal-blog/src/components/seo";
import Header from "./header";
import Footer from "./footer";
import CodeStyles from "@lekoarts/gatsby-theme-minimal-blog/src/styles/code";
import SkipNavLink from "@lekoarts/gatsby-theme-minimal-blog/src/components/skip-nav";
import "../styles/fade-in.css";

type LayoutProps = { children: React.ReactNode; className?: string };

const Layout = ({ children, className = `` }: LayoutProps) => (
  <React.Fragment>
    <style>
      @import
      url('https://fonts.googleapis.com/css2?family=Hahmlet:wght@200&display=swap');
    </style>
    <Global
      styles={(theme) => ({
        "*": {
          boxSizing: `inherit`
        },
        html: {
          WebkitTextSizeAdjust: `100%`,
          background: theme.colors.background
        },
        body: {
          background: theme.colors.background
        },
        img: {
          borderStyle: `none`
        },
        pre: {
          fontFamily: `monospace`,
          fontSize: `1em`
        },
        "[hidden]": {
          display: `none`
        },
        "::selection": {
          backgroundColor: theme.colors.text,
          color: theme.colors.background
        },
        a: {
          transition: `all 0.3s ease-in-out`,
          color: `text`
        }
      })}
    />
    <Seo />
    <SkipNavLink>Skip to content</SkipNavLink>
    <Container>
      <Header />
      <Box id="skip-nav" sx={{ ...CodeStyles }} className={className}>
        {children}
      </Box>
      <Footer />
    </Container>
  </React.Fragment>
);

export default Layout;
