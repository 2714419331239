/** @jsx jsx */
import { Link } from "gatsby";
import { jsx } from "theme-ui";
import replaceSlashes from "@lekoarts/gatsby-theme-minimal-blog/src/utils/replaceSlashes";
import useSiteMetadata from "@lekoarts/gatsby-theme-minimal-blog/src/hooks/use-site-metadata";
import useMinimalBlogConfig from "@lekoarts/gatsby-theme-minimal-blog/src/hooks/use-minimal-blog-config";

const HeaderTitle = () => {
  const { siteTitle } = useSiteMetadata();
  const { basePath } = useMinimalBlogConfig();

  return (
    <Link
      to={replaceSlashes(`/${basePath}`)}
      aria-label={`${siteTitle} - Back to home`}
      sx={{
        color: `heading`,
        textDecoration: `none`,
        fontFamily: "Fjalla One"
      }}
    >
      <div
        sx={{
          my: 0,
          mt: 4,
          fontWeight: `medium`,
          fontSize: [8, 8],
          lineHeight: [1],
          width: ["270px", "auto", "auto"]
        }}
      >
        YEAR IN{" "}
        <span
          sx={{
            color: "rgb(240, 85, 55)"
          }}
        >
          RUN
        </span>
        NING
      </div>
    </Link>
  );
};

export default HeaderTitle;
